import axios from "axios";
import config from "@/config";
import configClient from "@/config.client";
import * as Sentry from "@sentry/nextjs";
import MembershipEnquiry from "@/types/marketing/MembershipEnquiry";
import ElevateEnquiry from "@/types/marketing/ElevateEnquiry";
import AllianceEnquiry from "@/types/marketing/AllianceEnquiry";
import AccelerateEnquiry from "@/types/marketing/AccelerateEnquiry";
import SponsorshipEnquiry from "@/types/marketing/SponsorshipEnquiry";
import ProtectedApiClient from "./ProtectedApiClient";

class EnquiryClient extends ProtectedApiClient {
  private zapierEndpoint: string;

  constructor() {
    super();
    this.zapierEndpoint = configClient.ZAPIER_URL || config.ZAPIER_URL;
  }

  public async enquireAboutMemberships(
    body: MembershipEnquiry
  ): Promise<boolean> {
    try {
      const url = `${this.apiEndpoint}/v1/enquiries/membership`;
      const response = await axios.post(url, body, this.axiosConfig);

      return response.data?.success;
    } catch (err) {
      Sentry.captureException(err);
    }

    return false;
  }

  public async enquireAboutElevate(body: ElevateEnquiry): Promise<boolean> {
    try {
      const url = `${this.apiEndpoint}/v1/enquiries/elevate-irl`;
      const response = await axios.post(url, body, this.axiosConfig);

      return response.data?.success;
    } catch (err) {
      Sentry.captureException(err);
    }

    return false;
  }

  public async enquireAboutAccelerate(
    body: AccelerateEnquiry
  ): Promise<boolean> {
    try {
      const url = `${this.apiEndpoint}/v1/enquiries/accelerate-irl`;
      const response = await axios.post(url, body, this.axiosConfig);

      return response.data?.success;
    } catch (err) {
      Sentry.captureException(err);
    }

    return false;
  }

  public async enquireAboutAlliance(body: AllianceEnquiry): Promise<boolean> {
    try {
      await axios.post(`${this.zapierEndpoint}/14476653/37hn68l`, body, {
        transformRequest: (data, headers) => {
          delete headers.authorization;
          return JSON.stringify(data);
        },
      });

      return true;
    } catch (err) {
      Sentry.captureException(err);
    }

    return false;
  }

  public async enquireAboutSponsorship(
    body: SponsorshipEnquiry
  ): Promise<boolean> {
    try {
      await axios.post(`${this.zapierEndpoint}/19120435/2ombw8f`, body, {
        transformRequest: (data, headers) => {
          delete headers.authorization;
          return JSON.stringify(data);
        },
      });

      return true;
    } catch (err) {
      Sentry.captureException(err);
    }

    return false;
  }

  protected get axiosConfig() {
    return {
      headers: {
        "x-api-key": this.apiKey,
      },
    };
  }
}

export default EnquiryClient;
